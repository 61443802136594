import './DateTimeModal.css';
import React from 'react';
import DateTimePicker from 'react-datetime-picker';

class DateTimeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valType: 'default',
            val: null,
            valFormatted: null
        };
        this.changeValType = this.changeValType.bind(this);
        this.changeVal = this.changeVal.bind(this);
    }
    changeValType(ev) {
        const valType = ev.target.value;
        this.setState((state) => ({
            valType: valType,
            val: valType === 'default' ? null : state.val,
            valFormatted: valType === 'default' ? null : state.val
        }));
    }
    changeVal(val) {
        if (this.state.valType === 'custom') {
            const newVal = new Date(val);
            const newValFormatted = this.formatVal(newVal);
            this.setState({
                val: val,
                valFormatted: newValFormatted
            });
        }
    }
    formatVal(val) {
        const dt = val;
        let yyyy = dt.getFullYear();
        let mm = (dt.getMonth() + 1).toString();
        mm = mm.length === 1 ? ('0' + mm) : mm;
        let dd = (dt.getDate()).toString();
        dd = dd.length === 1 ? ('0' + dd) : dd;
        let H = (dt.getHours()).toString();
        H = H.length === 1 ? ('0' + H) : H;
        let s = (dt.getMinutes()).toString();
        s = s.length === 1 ? ('0' + s) : s;
        let i = (dt.getSeconds()).toString();
        i = i.length === 1 ? ('0' + i) : i;
        const dtFormatted = `${yyyy}-${mm}-${dd}T${H}:${s}:${i}`;
        return dtFormatted;
    }
    render() {
        const modalStyle = {
            display: this.props.isVisible ? "block" : "none"
        };
    
        const modal = <div className="modal" tabIndex="-1" style={modalStyle}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-start">{this.props.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Zavřít" onClick={this.props.exitHandlerFunc}></button>
                    </div>
                    <div className="modal-body">
                        <p>Zvolte datum a čas</p>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="valType" id="valType1" onChange={this.changeValType} value="default" checked={this.state.valType === "default" ? 1 : 0} />
                            <label className="form-check-label" htmlFor="valType1">Výchozí: teď</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="valType" id="valType2" onChange={this.changeValType} value="custom" checked={this.state.valType === "custom" ? 1 : 0} />
                            <label className="form-check-label" htmlFor="valType2">
                                <span>Vlastní</span>: <DateTimePicker onChange={this.changeVal} value={this.state.val} format="dd-MM-y h:mm:ss" maxDate={new Date()} />
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.props.exitHandlerFunc}>Zavřít</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.props.confirmHandlerFunc(this.props.id, this.state.valFormatted)}>Potvrdit</button>
                    </div>
                </div>
            </div>
        </div>;

        return modal;
    }
}

export default DateTimeModal;