import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import OsTable from './components/OsTable';
import ReportsTable from './components/ReportsTable';
import React from 'react';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgName: 'DEK',
      reports: []
    }
    this.fetchReports = this.fetchReports.bind(this);
    this.updateReports = this.updateReports.bind(this);
  }
  updateReports(newReports) {
    this.setState({
      reports: newReports
    });
  }
  componentDidMount() {
    this.fetchReports();
  }
  fetchReports() {
    const that = this;
    axios.get('https://wsdp.phamtheanh.eu/api/get_reports.php').then((response) => {
        if (response.data) {
            that.setState({
              reports: response.data
            });
        }
    });
  }
  render() {
    return (
      <div className="App container">
        <h1>{this.state.orgName} CUZK WSDP APP</h1>
        <OsTable reportsUpdated={this.updateReports} />
        <ReportsTable reportsUpdated={this.updateReports} data={this.state.reports} />
      </div>
    );
  }
}

export default App;
