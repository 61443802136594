import './ReportsTable.css';
import axios from 'axios';
import React from 'react';
import LvTable from './LvTable';

class ReportsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dtModalId: null,
            dtModalIsVisible: false,
            dtModalTitle: null,
            dtModalConfirmHandler: null,
            dtModalVal: null,
            reportFname: null,
            visibleLvReportIndex: null
        }
        this.updateReport = this.updateReport.bind(this);
        this.toggleReportLvsVisibility = this.toggleReportLvsVisibility.bind(this);
    }
    toggleReportLvsVisibility(reportIndex) {
        this.setState(oldState => ({
            visibleLvReportIndex: reportIndex === oldState.visibleLvReportIndex ? null : reportIndex
        }));
    }
    updateReport(reportId) {
        const that = this;
        axios.post('https://wsdp.phamtheanh.eu/api/update_reports.php', {
            id: reportId
        }).then((response) => {
            if (response.data.status === 'SUCCESS') {
                that.props.reportsUpdated(response.data.data);
            }
            alert(response.data.msg);
        });
    }
    downloadReport(report, reportFType) {
        const reportId = report['id'];
        const that = this;

        if (report['stav'] === 'zaúčtován') {
            this.setState({
                reportFname: report['detaily'][reportFType + 'Soubor']
            });
            setTimeout(() => {
                document.getElementById('report-download-link').click();
                that.setState({
                    reportFname: null
                });
            }, 2000);
        } else {
            const purchaseConfirmed = window.confirm("Cena za stažení sestavy: " + report['detaily']['cena'] + " Kč.\nSouhlasíte se stažením?");
            if (purchaseConfirmed) {
                axios.post('https://wsdp.phamtheanh.eu/api/download_report.php', {
                    id: reportId,
                    fType: reportFType
                }).then((response) => {
                    if (response.data.status === 'SUCCESS') {
                        that.props.reportsUpdated(response.data.data);
                        that.setState({
                            reportFname: response.data.reportFname
                        });
                        alert(response.data.msg);
                        setTimeout(() => {
                            document.getElementById('report-download-link').click();
                            that.setState({
                                reportFname: null
                            });
                        }, 2000);
                    } else {
                        alert(response.data.msg);
                    }
                });
            }
        }
    }
    deleteReport(reportId) {
        const that = this;
        axios.post('https://wsdp.phamtheanh.eu/api/delete_report.php', {
            id: reportId
        }).then((response) => {
            if (response.data.status === 'SUCCESS') {
                that.props.reportsUpdated(response.data.data);
            }
            alert(response.data.msg);
        });
    }
    render() {
        let data = this.props.data;
        if (data.length === 0) {
            return (
                <div className="ReportsTable">
                    <h3>Sestavy</h3>
                    <b><i>Žádné sestavy</i></b>
                </div>
            );
        } else {
            return (
                <div className="ReportsTable">
                    <h3>Sestavy</h3>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                {Object.entries(data[0]).map(([reportKey, reportVal]) => (
                                    <th className={"text-start ReportsTable__col-" + reportKey} key={'0-' + reportKey}>{reportKey}</th>
                                ))}
                                <th className="text-start ReportsTable__lastCol">akce</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((report, reportIndex) => (
                                <React.Fragment key={"report-" + (reportIndex + 1)}>
                                    <tr className={"ReportsTable__row " + (report['stav'] === "zpracován" ? "report-ready " : "") + (report['stav'] === "zaúčtován" ? "report-accounted " : "")}>
                                        <td className="align-middle">{reportIndex + 1}</td>
                                        {Object.entries(report).map(([reportKey, reportVal]) => {
                                            if (typeof(reportVal) != 'object') {
                                                return <td className={"text-start align-middle ReportsTable__col-" + reportKey} key={(reportIndex + 1) + '-' + reportKey}>{reportVal}</td>;
                                            } else {
                                                return (
                                                    <td className={"text-start align-middle ReportsTable__col-" + reportKey} key={(reportIndex + 1) + '-' + reportKey}>
                                                        <table>
                                                            <tbody>
                                                                {Object.entries(reportVal).map(([repPropKey, repPropVal, reportIndex]) => {
                                                                    if (repPropKey === 'polozkyLv') {
                                                                        return (
                                                                            <tr key={(reportIndex + 1) + '-' + repPropKey}>
                                                                                <td><b>Počet LV objektů</b>:</td>
                                                                                <td>{repPropVal.length}</td>
                                                                            </tr>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <tr key={(reportIndex + 1) + '-' + repPropKey}>
                                                                                <td><b>{repPropKey}</b>:</td>
                                                                                <td>{repPropVal}</td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                )
                                            }
                                        })}
                                        <td className="text-start align-middle ReportsTable__lastCol">
                                            <button onClick={() => this.deleteReport(report['id'])} type="button" className="btn btn-danger mt-1 mb-1 btn-deleteReport" title="Odstranit sestavu"><i className="fa fa-minus-circle"></i></button>
                                            <button onClick={() => this.updateReport(report['id'])} type="button" className="btn btn-info mt-1 mb-1 btn-updateReport" title="Aktualizovat stav sestavy"><i className="fa fa-refresh"></i></button>
                                            <button onClick={() => this.downloadReport(report, 'xml')} type="button" className="btn btn-primary mt-1 mb-1 btn-downloadReport" title="Stáhnout sestavu v XML">
                                                <i className="fa fa-download"></i>
                                                <span>XML</span>
                                            </button>
                                            <button onClick={() => this.downloadReport(report, 'csv')} type="button" className="btn btn-success mt-1 mb-1 btn-downloadReport" title="Stáhnout sestavu v CSV">
                                                <i className="fa fa-download"></i>
                                                <span>CSV</span>
                                            </button>
                                            <button onClick={() => this.toggleReportLvsVisibility(reportIndex)} type="button" className="btn btn-secondary mt-1 mb-1 btn-toggleReportLvs" title="Zobrazit listy vlastnictví">LV</button>
                                        </td>
                                    </tr>
                                    <LvTable report={report} reportIndex={reportIndex} reportsUpdated={this.props.reportsUpdated} visibleLvReportIndex={this.state.visibleLvReportIndex} />
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <a id="report-download-link" href={'https://wsdp.phamtheanh.eu/sestavy/' + this.state.reportFname} target="_blank" rel="noreferrer" download></a>
                </div>
            );
        }
    }
}

export default ReportsTable;