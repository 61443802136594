import './LvTable.css';
import axios from 'axios';
import React from 'react';
import DateTimeModal from './DateTimeModal';

class LvTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dtModalId: null,
            dtModalIsVisible: false,
            dtModalTitle: null,
            dtModalConfirmHandler: null,
            dtModalVal: null,
            reportFname: null
        };
        this.requestLvs = this.requestLvs.bind(this);
        this.updateLvs = this.updateLvs.bind(this);
    }
    requestLvs() {
        const reportId = this.props.report['id'];
        const that = this;

        axios.post('https://wsdp.phamtheanh.eu/api/request_lvs.php', {
            reportId: reportId
        }).then((response) => {
            if (response.data.status === 'SUCCESS') {
                that.props.reportsUpdated(response.data.data);
            }
            alert(response.data.msg);
        });
    }
    updateLvs() {
        const reportId = this.props.report['id'];
        const that = this;

        axios.post('https://wsdp.phamtheanh.eu/api/update_lvs.php', {
            reportId: reportId
        }).then((response) => {console.log('response', response);
            if (response.data.status === 'SUCCESS') {
                that.props.reportsUpdated(response.data.data);
            }
            alert(response.data.msg);
        });
    }
    downloadLv(lvItem, reportFType) {
        const report = this.props.report;
        const reportId = report['id'];
        const lvItemId = lvItem['id'];
        const that = this;

        if (lvItem['stav'] == 'zaúčtován') {
            setTimeout(() => {
                const downloadLvLink = document.getElementById('lv-download-link');
                downloadLvLink.href = 'https://wsdp.phamtheanh.eu/sestavy/' + lvItem['detaily'][reportFType + 'Soubor'];
                downloadLvLink.click();
                downloadLvLink.href = null;
            }, 2000);
        } else {
            const purchaseConfirmed = window.confirm("Cena za stažení LV: " + lvItem['detaily']['cena'] + " Kč.\nSouhlasíte se stažením?");
            if (purchaseConfirmed) {
                axios.post('https://wsdp.phamtheanh.eu/api/download_lv.php', {
                    reportId: reportId,
                    lvItemId: lvItemId,
                    fType: reportFType
                }).then((response) => {
                    if (response.data.status === 'SUCCESS') {
                        that.props.reportsUpdated(response.data.data);
                        // that.setState({
                        //     reportFname: response.data.reportFname
                        // });
                        alert(response.data.msg);
                        setTimeout(() => {
                            const downloadLvLink = document.getElementById('lv-download-link');
                            downloadLvLink.href = 'https://wsdp.phamtheanh.eu/sestavy/' + response.data.reportFname;
                            downloadLvLink.click();
                            downloadLvLink.href = null;
                            // that.setState({
                            //     reportFname: null
                            // });
                        }, 2000);
                    } else {
                        alert(response.data.msg);
                    }
                });
            }
        }
    }
    render() {
        if (this.props.report['stav'] === 'zaúčtován') {
            if (!this.props.report['detaily']['polozkyLv'] || this.props.report['detaily']['polozkyLv'].length === 0) {
                return null;
            }

            const lvItems = this.props.report['detaily']['polozkyLv'];
            const firstLvItem = lvItems[0];

            return (
                <tr key={"report-lvs-row-" + this.props.reportIndex} className={(this.props.visibleLvReportIndex !== this.props.reportIndex ? "hidden " : "") + (firstLvItem['stav'] ? "lv-requests-sent" : "")}>
                    <td></td>
                    <td colSpan="1" className="text-center align-middle">
                        <b>Listy vlastnictví</b>
                        <br />
                        <button onClick={this.requestLvs} type="button" className="btn btn-warning mt-1 mb-1 btn-requestLvs" title="Požádat o listy vlastnictví">Požádat o LV</button>
                        <br />
                        <button onClick={this.updateLvs} type="button" className="btn btn-info mt-1 mb-1 btn-updateLvs" title="Aktualizovat listy vlastnictví">Aktualizovat LV</button>
                    </td>
                    <td colSpan="5">
                        <table className="table table-hover LvTable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {Object.keys(firstLvItem).map((lvItemPropKey) => (
                                        <th className={"text-start LvTable__col-" + lvItemPropKey} key={"th-" + lvItemPropKey}>{lvItemPropKey}</th>
                                    ))}
                                    <th class="ReportsTable__lastCol">akce</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lvItems.map((lvItem, lvItemIndex) => {
                                    let lvItemDownloadBtn = null;
                                    if(lvItem['stav'] === 'zpracován' || lvItem['stav'] === 'zaúčtován') {
                                        lvItemDownloadBtn = (
                                            <td class="ReportsTable__lastCol">
                                                <button onClick={() => this.downloadLv(lvItem, 'xml')} type="button" className="btn btn-primary mt-1 mb-1 btn-downloadLv" title="Stáhnout LV sestavu v XML">
                                                    <i className="fa fa-download"></i>
                                                    <span>XML</span>
                                                </button>
                                                <button onClick={() => this.downloadLv(lvItem, 'csv')} type="button" className="btn btn-success mt-1 mb-1 btn-downloadLv" title="Stáhnout LV sestavu v CSV">
                                                    <i className="fa fa-download"></i>
                                                    <span>CSV</span>
                                                </button>
                                            </td>
                                        );
                                    }
                                    return (
                                        <tr key={"tr-" + lvItemIndex}>
                                            <td>{lvItemIndex + 1}</td>
                                            {Object.entries(lvItem).map(([lvItemPropKey, lvItemPropVal]) => {
                                                if (lvItemPropKey !== 'detaily') {
                                                    return <td className={"text-start LvTable__col-" + lvItemPropKey} key={"tr-" + lvItemIndex + "-td-" + lvItemPropKey}>{lvItemPropVal}</td>;
                                                } else {
                                                    return (
                                                        <td key={"tr-" + lvItemIndex + "-td-" + lvItemPropKey}>
                                                            <table>
                                                                <tbody>
                                                                    {Object.entries(lvItemPropVal).map(([lvItemDetailPropKey, lvItemDetailPropVal]) => (
                                                                        <tr key={"tr-" + lvItemIndex + "-td-" + lvItemPropKey + "-detail-" + lvItemDetailPropKey}>
                                                                            <td>
                                                                                <b>{lvItemDetailPropKey}</b>:&nbsp;
                                                                            </td>
                                                                            <td>{lvItemDetailPropVal}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    );
                                                }
                                            })}
                                            {lvItemDownloadBtn}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <a id="lv-download-link" href={this.state.reportFname} target="_blank" download></a>
                    </td>
                </tr>
            );
        } else {
            return null;
        }
    }
}

export default LvTable;