import './OsTable.css';
import axios from 'axios';
import React from 'react';
import DateTimeModal from './DateTimeModal';

class OsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            reports: [],
            dtModalId: null,
            dtModalIsVisible: false,
            dtModalTitle: null,
            dtModalConfirmHandler: null,
            dtModalVal: null
        }
        this.fetchOsData = this.fetchOsData.bind(this);
        this.osAdd = this.osAdd.bind(this);
        this.osRemove = this.osRemove.bind(this);
        this.osEdit = this.osEdit.bind(this);
        this.osIsUpdated = this.osIsUpdated.bind(this);
        this.osAreAllUpdated = this.osAreAllUpdated.bind(this);
        this.osIsEmpty = this.osIsEmpty.bind(this);
        this.saveOsDataAll = this.saveOsDataAll.bind(this);
        this.requestEOR = this.requestEOR.bind(this);
        this.requestEstatesOwnershipReport = this.requestEstatesOwnershipReport.bind(this);
        this.dtModalExitHandler = this.dtModalExitHandler.bind(this);
    }
    componentDidMount() {
        this.fetchOsData();
    }
    fetchOsData() {
        const that = this;
        axios.get('https://wsdp.phamtheanh.eu/api/get_os_data.php').then((response) => {
            that.setState({
                data: response.data
            });
        });
    }
    osAreAllUpdated() {
        const data = this.state.data;
        for (let i = 0; i < data.length; i++) {
            if (data['idOS'] == null) {
                return false;
            }
        }
        return true;
    }
    osIsUpdated(osIndex) {
        return (this.state.data[osIndex]['idOS'] == null);
    }
    osIsEmpty(osIndex) {
        const os = this.state.data[osIndex];
        return (os.ico.trim().length === 0 || os.nazev.trim().length === 0)
    }
    osEdit(osIndex, osProperty, osNewVal) {
        let newData = this.state.data;
        newData[osIndex][osProperty] = osNewVal;
        this.setState({
            data: newData
        });
    }
    osAdd() {
        let newData = this.state.data;
        const emptyOs = {
            idOs: null,
            nazev: '',
            ico: '',
        };
        newData.push(emptyOs);
        this.setState({
            data: newData
        });
    }
    osRemove(osIndex) {
        if (this.state.data.length > 1) {
            let newData = this.state.data;
            newData.splice(osIndex, 1);
            this.setState({
                data: newData
            });
        }
    }
    saveOsDataAll() {
        let newOsData = this.state.data;
        let invalidOsArr = [];
        
        for (let i = 0; i < newOsData.length; i++) {
            let os = newOsData[i];
            for (const osProperty in os) {
                if (osProperty !== 'idOS' && typeof(os[osProperty]) === 'string') {
                    newOsData[i][osProperty] = os[osProperty].trim();
                }
            }
            if ((os['ico']).length === 0 || os['nazev'].length === 0) {
                invalidOsArr.push(i);
            }
        }

        if (invalidOsArr.length > 0) {
            invalidOsArr.sort((a, b) => b - a);
            for (let i = 0; i < invalidOsArr.length; i++) {
                newOsData.splice(invalidOsArr[i], 1);
            }
        }

        this.setState({
            data: newOsData
        });

        const that = this;
        axios.post('https://wsdp.phamtheanh.eu/api/post_os_data.php', {
            data: that.state.data
        }).then((response) => {
            if (response.data.status === 'SUCCESS') {
                that.setState({
                    data: response.data.data
                });
            }
            alert(response.data.msg);
        });
    }
    requestEOR(osIndex) {
        const idOS = this.state.data[osIndex]['idOS'];
        this.setState({
            dtModalId: idOS,
            dtModalIsVisible: true,
            dtModalTitle: 'Požádat o Přehled vlastnictví s nemovitostmi (OS číslo: ' + idOS + ')',
            dtModalConfirmHandler: this.requestEstatesOwnershipReport,
            dtModalVal: null
        });
    }
    requestEstatesOwnershipReport(idOS, datumK) {
        const that = this;
        let postData = {
            idOS: idOS,
            datumK: datumK
        };
        axios.post('https://wsdp.phamtheanh.eu/api/request_eor.php', postData).then((response) => {
            if (response.data.status === 'SUCCESS') {
                that.setState({
                    reports: response.data.data
                });
                that.props.reportsUpdated(response.data.data);
            }
            alert(response.data.msg);
        });
    }
    dtModalExitHandler() {
        this.setState({
            dtModalIsVisible: false
        });
    }
    render() {
        let data = this.state.data;
        if (data && typeof(data) == 'object') {
            if (data.length === 0) {
                return (
                    <div className="OsTable">
                        <h3>Oprávněné subjekty (OS)</h3>
                        <b><i>Žádné oprávněné subjekty</i></b>
                    </div>
                );
            } else {
                return (
                    <div className="OsTable">
                        <h3>Oprávněné subjekty (OS)</h3>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {Object.entries(data[0]).map(([osKey, osVal]) => (
                                        <th className="text-start" key={'0-' + osKey}>{osKey}</th>
                                    ))}
                                    <th className="text-start">akce</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((os, osIndex) => (
                                    <tr key={osIndex + 1} className={os['idOS'] == null ? 'os-error' : ''}>
                                        <td className="align-middle">{osIndex + 1}</td>
                                        {Object.entries(os).map(([osKey, osVal]) => (
                                            <td className="align-middle" key={(osIndex + 1) + '-' + osKey}>
                                                <input onChange={(ev) => this.osEdit(osIndex, osKey, ev.target.value)} className="form-control" type="text" value={osVal == null ? "" : osVal} readOnly={osKey === 'idOS'} disbaled={osKey === 'idOS' ? 1 : 0} />
                                            </td>
                                        ))}
                                        <td className="text-start">
                                            <button onClick={() => this.osRemove(osIndex)} type="button" className="btn btn-danger" title="Odstranit OS"><i className="fa fa-minus-circle"></i></button>
                                            &nbsp;
                                            <button onClick={() => this.requestEOR(osIndex)} type="button" className="btn btn-warning btn-requestEOR" title="Požádat o Přehled vlastnictví s nemovitostmi">Požádat o PVSN</button>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="100%">
                                        <button onClick={this.osAdd} type="button" className="btn btn-success m-1">
                                            <i className="fa fa-plus-circle"></i>&nbsp;
                                            <span>Přidat OS</span>
                                        </button>
                                        {/* <button onClick={this.fetchOsData} type="button" className="btn btn-warning m-1">
                                            <i className="fa fa-repeat"></i>&nbsp;
                                            <span>Reset formuláře</span>
                                        </button>
                                        <button type="button" className="btn btn-secondary m-1">
                                            <i className="fa fa-cloud-download"></i>&nbsp;
                                            <span>Aktualizovat OS</span>
                                        </button> */}
                                        <button onClick={this.saveOsDataAll} type="button" className="btn btn-primary m-1">
                                            <i className="fa fa-cloud-upload"></i>&nbsp;
                                            <span>Uložit všechna OS</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <DateTimeModal id={this.state.dtModalId} isVisible={this.state.dtModalIsVisible} title={this.state.dtModalTitle} confirmHandlerFunc={this.state.dtModalConfirmHandler} exitHandlerFunc={this.dtModalExitHandler} />
                    </div>
                );
            }
        }
    }
}

export default OsTable;